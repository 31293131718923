import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AOS from 'aos';
import 'aos/dist/aos.css';

//import local components below

import Footer from './components/common/Footer/Footer.js';
import WorkInProgress from './components/Pages/public/404/WorkInProgress.js';
import Header from './components/common/Header/Header.js';
import LandingPage from './components/Pages/public/LandingPage/LandingPage.js';
import Course from './components/Pages/client/Courses/Course.js';
import Login from './components/Pages/public/Auth/Login/Login.js';
import ProductDetail from './components/Pages/client/ProductDetail/ProductDetail.js';
import Dashboard from './components/Pages/admin/Dashboard/Dashboard.js';
import SearchableUsers from './components/Pages/admin/User Governance/Users/User.js';
import Product from './components/Pages/admin/Products/Product/Product.js';
import AdminCategory from './components/Pages/admin/Products/Categories/Category.js';
import Brand from './components/Pages/admin/Products/Brand/Brand.js';
import SearchableGroup from './components/Pages/admin/User Governance/Group/Group.js';
import Batch from './components/Pages/admin/User Governance/Batch/Batch.js';
import ProductAttribute from './components/Pages/admin/Products/ProductAttributes/ProductAttribute.js';
import UserProfile from './components/Pages/client/UserProfile/UserProfile';
import Cart from './components/Pages/client/Cart/Cart.js';
import Checkout from './components/Pages/client/Checkout/Checkout.js';
import Wishlist from './components/Pages/client/Wishlist/Wishlist.js';
import Category from './components/Pages/client/Category/index';
import Faq from './components/Pages/public/Faq/index';
import Qualification from './components/Pages/admin/QualificationConfiguration/Qualification/Qualification.js';
import Specialization from './components/Pages/admin/QualificationConfiguration/Specialization/Specialization.js';
import Privilage from './components/Pages/admin/Permissons/Privilege/Privilage.js';
import Roles from './components/Pages/admin/Permissons/Roles/Roles.js';
import Contact from './components/Pages/public/ContactMe/Contact.js';
import Features from './components/Pages/admin/Permissons/Features/Features.js';
import About from './components/Pages/public/AboutUs/About.js';
import OtherServices from './components/Pages/public/OtherServices/OtherServices.js';
import TermsConditons from './components/Pages/public/Terms&Conditions/Terms&Conditons.js';
import CancellationRefunds from './components/Pages/public/Cancellation&Refunds/Cancellation&Refunds.js';
import ShippingDelivery from './components/Pages/public/Shipping&Delivery/Shipping&Delivery.js';
import Privacy from './components/Pages/public/Privacy/Privacy.js';
const App = () => {
  // const [auth, setAuth] = useState(null);

  const { isAuth, group_id } = useSelector(state => state.login);
  const location = useLocation();
  const isAdminDashboard = location.pathname.includes('admindashboard');
  useEffect(() => {
    // var session = sessionStorage.getItem('session_id');
    // auth && session ? setAuth(true) : setAuth(false);
  }, []);

  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div>
      {(isAuth === true && isAdminDashboard) ? '' : <Header style={{ merginBottom: 10 }} />}

      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/signin" element={<Login />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/user-profile/:userId" element={<UserProfile />} />
        <Route path="/allcourses/:category_id" element={<Course />}></Route>
        <Route path="/allcourses/search/:search_term" element={<Course />}></Route>
        <Route path="/all-category" element={<Category />}></Route>
        <Route path="/productdetails/:product_id" element={<ProductDetail />} />
        <Route path="/cart/:userId" element={<Cart />} />
        <Route path="/wishlist/:userId" element={<Wishlist />} />
        <Route path="/checkout/:userId" element={<Checkout />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-conditions" element={<TermsConditons />} />
        <Route path="/cancellation-refunds" element={<CancellationRefunds />} />
        <Route path="/shipping-delivery" element={<ShippingDelivery />} />
        <Route path="/admindashboard" element={<Dashboard />}>
          <Route path="user" element={<SearchableUsers />} />
          <Route path="user-profile/:userId" element={<UserProfile />} />
          <Route path="allproducts" element={<Product />} />
          <Route path="categories" element={<AdminCategory />} />
          <Route path="brand" element={<Brand />} />
          <Route path="group" element={<SearchableGroup />} />
          <Route path="batch" element={<Batch />} />
          <Route path="attribute" element={<ProductAttribute />} />
          <Route path="qualification" element={<Qualification />} />
          <Route path="specialization" element={<Specialization />} />
          <Route path="privilage" element={<Privilage />} />
          <Route path="roles" element={<Roles />} />
          <Route path="features" element={<Features />} />
        </Route>
        <Route path="/seo-services" element={<OtherServices />}>
          <Route path=":slug" element={<>seo-services/slug</>} />
        </Route>
        <Route path="*" element={<WorkInProgress />} />
      </Routes>

      {(group_id === 4 || group_id === null) && <Footer />}
    </div>
  );
};

export default App;
